import { useEffect, lazy, Suspense } from 'react';
import { Routes, Route } from "react-router-dom";
import ScrollToTop from './components/sections/ScrollToTop';
import LazyLoader from './components/singles/LazyLoader';

const Home = lazy(() => import('./pages/Home'));

const Features = lazy(() => import('./pages/Features'));
const TabletPos = lazy(() => import('./pages/TabletPos'));
const WebPos = lazy(() => import('./pages/WebPos'));
const MobilePos = lazy(() => import('./pages/MobilePos'));
const BackOffice = lazy(() => import('./pages/BackOffice'));
const Offline = lazy(() => import('./pages/Offline'));

const PartnersIntegration = lazy(() => import('./pages/PartnersIntegration'));
const Management = lazy(() => import('./pages/Management'));
const Ecosystem = lazy(() => import('./pages/Ecosystem'));
const Loyalty = lazy(() => import('./pages/Loyalty'));
const Privacy = lazy(() => import('./pages/Privacy'));

const WhyUs = lazy(() => import('./pages/WhyUs'));
const About = lazy(() => import('./pages/About'));
const Contact = lazy(() => import('./pages/Contact'));
const Pricing = lazy(() => import('./pages/Pricing'));
const Error = lazy(() => import('./pages/Error'));
const Login = lazy(() => import('./pages/Login'));
const Faq = lazy(() => import('./pages/Faq'));

function App() {
  useEffect(() => {
    window.addEventListener('scroll', isSticky);
  }, []);

  const isSticky = (e) => {
    const btt = document.querySelector('.back-to-top');
    const scrollTop = window.scrollY;
    if (scrollTop >= 50) {
      btt.classList.add('opacity-100')
      btt.classList.remove('opacity-0')
    } else {
      btt.classList.add('opacity-0')
      btt.classList.remove('opacity-100');
    }
  };

  return (
    <div className="btt-wrapper" id="btt">
      <ScrollToTop />

      <Suspense fallback={<LazyLoader />}>
        <Routes>
          <Route path="/" element={<Home />} />

          <Route path="/features">
            <Route index element={<Features />} />
            <Route path="tablet-pos" element={<TabletPos />} />
            <Route path="web-pos" element={<WebPos />} />
            <Route path="mobile-pos" element={<MobilePos />} />
            <Route path="back-office" element={<BackOffice />} />
            <Route path="offline" element={<Offline />} />
          </Route>

          <Route path="/platform-integration">
            <Route index element={<PartnersIntegration />} />
            <Route path="management" element={<Management />} />
            <Route path="ecosystem" element={<Ecosystem />} />
            <Route path="loyalty" element={<Loyalty />} />
            <Route path="privacy" element={<Privacy />} />
          </Route>

          <Route path="/pricing" element={<Pricing />} />
          <Route path="/about" element={<About />} />
          <Route path="/why-us" element={<WhyUs />} />
          <Route path="/contact" element={<Contact />} />

          <Route path="/faq" element={<Faq />} />
          <Route path="/login" element={<Login />} />
          <Route path="*" element={<Error />} />
        </Routes>
      </Suspense>

      <a href="#btt" className="back-to-top position-fixed bottom-0 end-0 me-4 mb-4 theme-icon icon-primary rounded-full opacity-0">
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" viewBox="0 0 16 16">
          <path fillRule="evenodd" d="M8 15a.5.5 0 0 0 .5-.5V2.707l3.146 3.147a.5.5 0 0 0 .708-.708l-4-4a.5.5 0 0 0-.708 0l-4 4a.5.5 0 1 0 .708.708L7.5 2.707V14.5a.5.5 0 0 0 .5.5z" />
        </svg>
      </a>
    </div>
  );
}

export default App;
